.frameDialog {
    position: fixed;
    z-index: 9999;
    width: 400px;
    height: 300px;
}
.frameDialog .card {
    background-color: #fff;
    color: #000;
    padding: 15px;
    box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%);
    margin: 10px;
    border-radius: 2px;
    font-size: 1rem;
    display: grid !important;
}

.frameDialog .card .title {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 0px 10px 10px 10px;
}

.frameDialog .card span{
    float: right;
}

.frameDialog .card .body {
    padding: 10px;
}

.slidecontainer {
    /*width: 100%; /* Width of the outside container */
    position: initial;
}

datalist {
    display: inline-block;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    -webkit-writing-mode: vertical-rl;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(180deg);
    width: 95%;
    font-size: 0.7rem;
    position: sticky;
  }
  
  option {
    padding: 0;
  }
  
  input[type="range"] {
    width: 100%;
    margin: 0;
  }

  .acciones .col-5{
    min-height: auto !important;
    margin-bottom: 20px;
  }

  .acciones input{
    width: 95%;
    height: 30px;
  }

  .acciones a{
    cursor: pointer;
    border: 1px solid #5ea7e0;
    color: #5ea7e0;
    padding: 5px;
    display: flow-root;
    text-align: center;
  }

  .acciones a:hover{
    background-color: #5ea7e0;
    color: #fff;
  }

  @media screen and (max-width: 640px) {
    .frameDialog {
        left: 0px !important;
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
     appearance: none;
     background: transparent;
     cursor: pointer;
 }
 
 /* Removes default focus */
 input[type="range"]:focus {
   outline: none;
 }
 
 /***** Chrome, Safari, Opera and Edge Chromium styles *****/
 /* slider track */
 input[type="range"]::-webkit-slider-runnable-track {
    background-color: #b5b5b5;
    border-radius: 0.5rem;
    height: 0.5rem;  
 }
 
 /* slider thumb */
 input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -17px; /* Centers thumb on the track */
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-top: 10px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 15px solid #ff0000;
 }
 
 /* input[type="range"]:focus::-webkit-slider-thumb {   
   border: 1px solid #053a5f;
   outline: 3px solid #053a5f;
   outline-offset: 0.125rem; 
 } */
 
 /******** Firefox styles ********/
 /* slider track */
 input[type="range"]::-moz-range-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;
 }
 
 /* slider thumb */
 input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
 
    /*custom styles*/
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
 }
 
 /* input[type="range"]:focus::-moz-range-thumb {
   border: 1px solid #053a5f;
   outline: 3px solid #053a5f;
   outline-offset: 0.125rem; 
 } */

 .active-invest{
  color: #ff0000;
 }