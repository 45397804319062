.footer {
  width: 100%;
  background: #f6f5f2; /* linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  z-index: 6;
  color: #000;
  text-align: center;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 20px;
}

.footer .social {
  color: #000;
}

.footer a i {
  color: #000;
  font-size: 2rem;
  margin: 10px;
}

.footer .text-note {
  font-size: 1rem;
  padding: 20px;
  text-align: justify;
}

.footer h1 {
  color: #000 !important;
  text-align: left;
  font-size: 1.5rem;
  padding-bottom: 10px;
  line-height: 4rem;
  margin-right: 20px;
}

.footer h2.button{
  padding: 15px 20px;
  font-size: 1.5rem;
  border: solid 1px;
  color: #fff;
  text-decoration: none;
  transition: .8s;
  background-color: #000;
  cursor: pointer;
}

.footer h2.button > a{
  color: #fff !important;
  text-decoration: none;
}

.footer .section-2{
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 30px 0px;
}

.footer .social-links{
  text-align: right;
}

.footer .logos-section{
  text-align: left;
  display: flex;
  /* margin-left: 20px; */
}

.footer .logos-section img{
  width: 150px;
  height: auto;
  max-height: 100px;
  margin-right: 30px;
  object-fit: contain;
}

.footer .footer-text{
  margin-top: 20px;
}

.footer .footer-text a{
  text-decoration: none;
}

.social-links a {
  padding: 30px;
  color: #000;
  text-decoration: none;
}

.social-links a:hover {
  color: #4297ec;
}

@media screen and (max-width: 960px) {
  .footer h2.button, .footer h1 {
    font-size: 1rem;
    float: initial;
    text-align: center;
  }
  .footer h2.button {
      padding: 15px 20px;
      font-size: 1.5rem;
      border: solid 1px;
      color: #fff;
      text-decoration: none;
      transition: .8s;
      background-color: #000;
      cursor: pointer;
  }
  .footer .social-links{
    padding: 25px 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .footer h2.button, .footer h1{
    font-size: 1rem;
    float: initial;
    text-align: center;
  }
  .footer .padding-30{
    padding: 0px;
  }
  .footer .social-links{
    text-align: center;
    padding: 25px 0px !important;
  }
  .social-links a{
    padding: 10px;
  }
}