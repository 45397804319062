.verticalCardImage img {
  -webkit-transition: width 2s;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 0.9;
  object-fit: cover;
}

.verticalCardImage:hover {
  cursor: pointer;
}

.verticalCardImage:hover img {
  transition: all 1000ms;
  width: 70%;
  opacity: 0.4;
}

.verticalCardImage:hover .textBox {
  transition: all 1000ms;
  color: #d5d268;
}

.verticalCardImage {
  position: relative;
  height: 500px;
  background-color: black;
  display: flex;
  margin: 3px;
}

.verticalCardImage .textBox {
  padding: 10px;
  position: absolute;
  display: block;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  bottom: 0;
  width: 100%;
}

.verticalCardImage .textBox h2,
.verticalCardImage .textBox p {
  width: 100%;
  flex-direction: row;
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  border: 1px solid gray;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.modal .header {
  background-color: black;
  width: 100%;
  height: 5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .header p {
  width: 100%;
  margin-left: 20px;
}

.modal span {
  color: #fff;
  font-size: 3rem;
  padding: 10px;
  float: right;
  cursor: pointer;
}

.modal .wrapper {
  height: 90%;
  width: 100%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal img {
  max-width: 800px;
  max-height: 90%;
}

.modal .btn-prev,
.modal .btn-next {
  width: 60px;
  height: 60px;
  background-color: #fff;
  color: #000;
  font-size: 42px;
  cursor: pointer;
  vertical-align: middle;
  display: inline-table;
}

.modal .btn-prev:hover,
.modal .btn-next:hover {
  background-color: #464646;
  color: #fff;
}

.modal .btn-prev {
  position: absolute;
  top: 45%;
  left: 0;
}

.modal .btn-next {
  position: absolute;
  top: 45%;
  right: 0;
}
