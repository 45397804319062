video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  object-fit: contain;
}

.home{
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  height: 600px;
  text-align: center;
  display: inline-block !important;
}

.home h1{
  padding: 0px 20px;
  color: #fff !important;
  margin-bottom: 0px !important;
  font-size: 2.5rem !important;
  width: 60%;
  margin-top: 460px !important;
  text-align: left !important;
  line-height: 3rem;
  letter-spacing: 1px;
}

.home > h3.subtitle {
  color: #fff;
  
}

.nuestro-productos h1{
  color: #000 !important;
  font-family: Kefa-Regular !important;
  font-size: 1.5rem;
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
  line-height: 2rem;
}

.nuestro-productos .content-card{
  border: 1px solid #ccc;
  box-shadow: 5px 5px 5px #ccc;
}

/* .image{
  width: 100%;
  height: 250px;
} */

.image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.nuestro-productos .image{
  height: auto !important;
}


.nuestro-productos .description h2{
  margin-bottom: 10px;
    font-family: Kefa-Regular;
    /* padding: 10px; */
    text-align: left;
    display: inline-block;
    width: 100%;
    font-size: 1.3rem;
}

.nuestro-productos .description p{
  display: block;
  margin: 0;
}

/* .nuestro-productos .description a{
  color: #a72d96;
  font-family: Kefa-Regular;
  border: #a72d96 1px solid;
  padding: 5px;
  cursor: pointer;
} */

.nuestro-productos .inversion-completa{
  border: 1px solid #35ac75;
  padding: 5px;
  font-family: Kefa-Regular;
  color: #fff;
  background: #35ac75;
}

.nuestro-productos .description{
  margin:0 auto;
  text-align: center;
}

.nuestro-productos h2.button {
  background-color: #f4f3f3 !important;
  border-radius: 0px !important;
  border: 0px!important;
  box-shadow: none !important;
  font-family: Kefa-Regular;
  color: #000;
  transition: .8s;
}

/* .nuestro-productos h2.button:hover {
  background-color: #000 !important;
  color: #fff;
  transition: .8s;
} */

.nuestro-productos h2.button a {
  font-family: Kefa-Regular;
  text-decoration: underline;
  font-size: 1.3rem;
}

.nuestro-productos h2.button a:hover,.nuestro-productos h2.button:hover a{
  color: #4297ec;
}

h2.button-vermas {
  width: 30% !important;
  font-size: 1rem !important;
  padding: 10px !important;
  background: #fff !important;
  border: 1px solid #4a4b4b !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  cursor: pointer;
}

h2.button-vermas:hover {
  background-color: #4a4b4b !important;
}

h2.button-vermas:hover > a {
  color: #fff !important;
}

.alice-carousel__wrapper{
  margin-bottom: 0px;
}

/* .content-card {
  border: 1px solid rgb(103, 103, 103);
  box-shadow: 5px 5px 5px #ccc;
} */

.conocenos .content-card-conocenos{
  width: 100%;
  background: #fff;
}

.conocenos li{
  background: transparent;
  box-shadow: none;
  /* height: 490px; */
  margin-top: 20px;
}
.conocenos h1{
  color: #000 !important;
  font-family: Kefa-Regular !important;
  text-align: left;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  line-height: 2rem;
}

.conocenos .alice-carousel__next-btn-item span,.conocenos .alice-carousel__prev-btn-item span{
  background: #1a1818;
  padding: 14px 20px;
  color: #fff;
}

.alice-carousel{
  margin-bottom: 20px !important;
}

/* .image{
  width: 100%;
  height: 350px;
  object-fit: cover;
} */

.image img{
  width: 100%;
  height: 100%;
  object-position: center;
}

.conocenos .description h2{
  position: absolute;
  margin-top: -20px;
  font-size: 1.1rem;
  background: #f6f5f2;
  color: #703438;
  padding: 2px 15px;
  margin-left: 30px;
}

.conocenos .description p{
  float: left;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: justify;
}

.conocenos .description a{
  color: #703438;
  margin-right: 10px;
}

.conocenos .description a:hover {
  color: #4297ec;
}

.conocenos .description{
  margin:0 auto;
  /* padding: 0px 20px 20px 20px; */
  text-align: center;
  display: grid;
}

.conocenos h2.button {
  background-color: #a72d96 !important;
  border-color: #a72d96 !important;
  border-radius: 0px !important;
  border: 1px solid !important;
  box-shadow: none !important;
  font-family: Kefa-Regular;
  color: #fff;
}

.conocenos h2.button:hover {
  background-color: #fff !important;
  border-color: #a72d96 !important;
  color: #a72d96;
}

.hero-container > h1 {
  color: #036873;
  font-family: Kefa-Regular;
  font-size: 4rem;
  text-align: center;
  margin: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}

.listado-nosotros .button{
  background-color: #f4f3f3 !important;
  border-radius: 0px !important;
  border: 0px!important;
  box-shadow: none !important;
  font-family: Kefa-Regular;
  color: #000;
  transition: .8s;
  color: #fff;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.listado-nosotros .button a{
  color: #000 !important;
}

.listado-nosotros .button a:hover{
  color:#4297ec !important;
}

.listado-nosotros h1{
  color: #4a4b4b !important;
  font-family: Kefa-Regular !important;
}

.quotes{
  background-color: #1a1818;
}

.quotes h1{
  color: #fff !important;
  font-family: Kefa-Regular !important;
}

.quotes li {
  padding: 10px;
  box-shadow: none;
  padding: 20px;
}

.faqs h1{
  color: #4a4b4b !important;
  font-family: Kefa-Regular !important;
}

.hero-container > h2 {
  color: #fff;
  font-size: 2rem;
  /* margin-top: -100px; */
  width: 100%;
  text-align: center;
  margin: 0;
  background-color: #2da795;
  border-radius: 10px;
  border: 2px solid #062722;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 75%);
  font-family: Kefa-Regular;
}

.home h2.button {
  background-color: rgba(255,255,255,0.1) !important;
  border-color: #fff !important;
  border-radius: 0px !important;
  border: 1px solid !important;
  box-shadow: none !important;
  font-family: Kefa-Regular;
}

.home h2.button a {
  color: #fff;
  font-family: Kefa-Regular;
  text-decoration: none;
}

.hero-container h2.button{
  background-color: #2da795;
}

.home h2.button:hover{
  color: rgb(249 247 135);
  border:  1px solid !important;
}

.home h2.button a:hover {
  color: rgb(249 247 135);
}

.hero-container h2.button:hover{
  cursor: pointer;
  background-color: #062722;
}

.hero-container a{
  text-decoration: none;
  margin: 0 auto;
  color: #000;
}
.hero-container h3{
  font-size: 1.1rem;
  padding: 25px 0px;
  text-align: left;
  border-bottom: 2px solid;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: Kefa-Regular;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.title-over-image{
  color: #ffffff !important;
  font-size: 3rem !important;
  text-align: left;
  margin-left: 30px !important;
  padding-top: 25rem;
  position: absolute;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 960px) {
  .hero-container.home {
    height: 400px;
  }
  .hero-container > h1 {
      font-size: 1rem !important;
      margin-top: 320px !important;
      line-height: 21px;
      width: 100%;
  }

  .button-section-1{
    padding: 30px 0px !important;
    font-size: 1rem;
    text-align: center;
  }
  .faqs .content {
    width: 100% !important;
    margin: 0 auto;
  }
  h2.button-vermas {
    width: 70% !important;
  }
  .title-box h1{
    font-size: 1rem !important;
    margin-bottom: 25px !important;
    text-align: center !important;
  }

  .title-box h2{
    text-align: center;
    font-size: 1rem;
  }

  .title-box .subtitle{
    font-size: 1rem !important;
    text-align: center;
    margin-top: 5px;
  }
  .button-investment{
    display: contents;
  }
  .col-3-reverse{
    width: 100% !important;
  }
  .title-over-image{
    font-size: 3rem !important;
    text-align: left !important;
    padding-top: 25rem;
  }
}

.quienes_somos .col{
  display: flex;
}
.quienes_somos a{
  color: #036873 !important;
}

.quienes_somos a{
  display: flex;
  align-items: center;
  justify-content: center;
}

.comparative{
  background: #f6f6f6;
  padding: 100px 0px;
}

.comparative span{
  font-family: Kefa-Regular;
  font-size: 2rem;
}

.comparative .left{
  color: #036873;
}

.comparative .right{
  color: #000;
}

/* .row {
  width: 80% !important;
} */

.carousel .slide img {
  max-height: 400px !important;
  object-fit: contain !important;
}

.alice-carousel__wrapper img {
  width: 100%;
  object-fit: cover;
}


element.style {
  width: 412.593px;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 20px !important;
  margin-left: 0px !important;
  box-sizing: border-box;
  width: 100%;
  /* height: 300px !important; */
  vertical-align: top;
  white-space: normal;
  line-height: 0;
  /* background: #fff; */
  display: inline-flex !important;
  object-fit: cover !important;
  /* box-shadow: 5px 5px 5px #ccc; */
}

.alice-carousel__stage {
  /* height: 350px !important; */
}

.alice-carousel__prev-btn-item span,.alice-carousel__next-btn span {
  width: 100px;
  height: 100px;
  background: #1a1818;
  padding: 14px 20px;
  color: #fff;
}

.alice-carousel__dots{
  margin: 0 3px 5px !important;
  display: none;
}

.content-quotes {
  display: flow-root;
  text-align: justify;
  width: 100%;
  padding: 20px;
  margin: 0px;
  background: #fff;
}

.content-quotes h2{
  margin-bottom: 20px;
  color: #036873;
}

.quotes .alice-carousel__next-btn-item span, .quotes .alice-carousel__prev-btn-item span {
  background-color: #fff;
  color: #000;
}

.faqs {
  width: 100%;
  margin: 0 auto;
  display: inherit;
  margin-bottom: 20px;
}

.title-box h1{
  color: #000 !important;
  text-align: left;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  line-height: 2rem;
}

.faqs .content {
  width: 1000px;
  margin: 0 auto;
}

.question{
  background: #f5f5f2;
  padding: 20px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid; 
}

.listado-nosotros ul{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.listado-nosotros li{ 
  list-style: none;
}

.Collapsible__trigger {
  font-size: 1.5rem;
  cursor: pointer;
  color: #2b6873;
  font-family: Kefa-Regular;
}

.Collapsible__contentInner p{
  font-family: Kefa-Regular;
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .alice-carousel__stage-item {
    display: inline-block !important;
  }
  .title-box a.button{
    padding: 15px;
    border: solid 1px;
    color: #000;
    text-decoration: none;
    transition: .8s;
  }
}

.modal-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgb(0 0 0 / 70%);
  z-index: 999;
}

/* new style */

.container {
  margin: 0 auto;
  padding: 25px;
  position: relative;
}

a.button{
  padding: 15px;
  border: solid 1px;
  color: #000;
  text-decoration: none;
  transition: .8s;
  cursor: pointer;
}

a.button:hover{
  color: #fff;
  background-color: #000;
  transition: .8s;
  cursor: pointer;
}

.button:hover{
  cursor: pointer;
}

a:hover{
  cursor: pointer;
}

.nuestro-productos {
  display: contents;
}

.title-box {
  background: #f8f6f1;
  padding: 30px 10px;
  width: 100%;
}

.title-box a.button{
  padding: 15px;
  border: solid 1px;
  color: #000;
  text-decoration: none;
  transition: .8s;
  font-size: 1rem;
  display: inline-block;
}

.title-box  a.button:hover{
  color: #fff;
  background-color: #000;
  transition: .8s;
}

.border-bottom-1{ border-bottom: 1px #000 solid;}

.hero-container h3.subtitle{
  font-size: 1.2rem;
  padding: 10px;
  border-bottom: 0px;
}

.img-proyecto{
  object-fit: cover !important;
  aspect-ratio: 1 / 1;
}

.links-card {
  text-align: left;
}

.links-card a{
  color: #000 !important;
  border: none !important;
  text-decoration: underline;
  padding: 0px;
  cursor: pointer;
}

.links-card a:hover{
  color: #4297ec !important;
  background: none !important;
}

/* .links-card a.button{
  padding: 15px;
  border: solid 1px;
  color: #000;
  text-decoration: none;
  transition: .8s;
}

.links-card a.button:hover{
  color: #fff;
  background-color: #000;
  transition: .8s;
} */

.content-card-conocenos img{
  width: 100% !important;
  object-fit: cover !important;
  aspect-ratio: 1 / 1 !important;
  height: auto !important;
}

.title-name span{
  float: right;
}

.title-bg{
  height: 500px;
  display: block;
}

.hero-container-section {
  /* background: url(/images/img-home.jpg) center center/cover no-repeat; */
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

h3.description-text {
  padding: 35px 0px;
  text-align: justify;
  width: 100%;
  font-size: 1rem;
}

.faqs-list{
  /* columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;  */
  margin-top: 25px;
}

.faqs li{
  background: #f5f5f2;
  padding: 0px;
  page-break-inside: avoid;
  break-inside: avoid;
  list-style: none;
  display: table-row;
}

.faqs-list-element{
  padding: 40px;
  margin: 0px 0px 20px 0px;
  border: 1px solid #000;
  text-align: center;
  font-size: 1.4rem;
  transition: .8s;
}

.faqs-list-element p{
  /* overflow: hidden;  */
  /* border-right: .15em solid orange; The typwriter cursor */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  height: 100px;
  width: 50%;
  /* padding: 40px; */
  background: #fff;
  vertical-align: middle;
  display: table-cell;
  border: 1px solid #000;
}

/* .faqs-list-element p:hover{
  background: #f5f5f2;
  font-size: .8rem;
  height: 100px;
  content: attr(data-respuesta);
} */

/* .faqs-list-element p:hover span{
  display:none;
} */

/* .faqs-list-element p:hover:before{
  content: attr(data-respuesta);
} */

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

@media screen and (max-width: 768px) {
  h2.button-vermas {
    width: 95% !important;
  }
  .hero-container > h1 {
    font-size: 2rem;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
  .button-investment{
    display: contents;
  }

  .col-3-reverse{
    width: 100% !important;
  }
  .title-over-image{
    font-size: 2rem !important;
    text-align: left !important;
    padding-top: 25rem;
  }
  .faqs-list{
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .listado-nosotros ul{
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  .listado-nosotros ul li {
    margin-bottom:10px
  }

  .faqs-list{
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    font-size: 1rem;
  }
  
  .faqs-list-element p{
    padding: 10px 10px;
    height: 130px;
  }
}

.flip-card {
  background-color: transparent;
  /* width: 300px; */
  height: auto;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px;
  padding: 4px;
  display: grid;
  min-height: 200px;
  margin-top: 10px;
}
/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  /* height: 100%; */
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  /* margin: 0 auto; */
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
  display: flex;
  min-height: 200px;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #000;
  color: white;
  font-size: 1.5rem;
}

/* Style the back side */
.flip-card-back {
  background-color: #013e42;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-front h1, .flip-card-back h1{
  padding: 25% 0;
}

.flip-card-front span,.flip-card-back p{
  margin: auto;
}

.faqs-list.row{
  display: flow-root !important;
}