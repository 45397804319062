* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Kefa-Regular, "PT Sans", sans-serif;
}

@font-face {
  font-family: Kefa-Regular;
  src: url(assets/fonts/Kefa-Regular.ttf); 
}
@font-face {
  font-family: Ralaway-Thin;
  src: url(assets/fonts/Raleway/static/Raleway-Thin.ttf); 
}
@font-face {
  font-family: Ralaway-Regular;
  src: url(assets/fonts/Raleway/Raleway-VariableFont_wght.ttf); 
}
@font-face {
  font-family: Ralaway-Bold;
  src: url(assets/fonts/Raleway/static/Raleway-Bold.ttf); 
}
@font-face {
  font-family: Antonio-Regular;
  src: url(assets/fonts/antonio/Antonio-Regular.ttf); 
}
@font-face {
  font-family: Antonio-Bold;
  src: url(assets/fonts/antonio/Antonio-Bold.ttf);
}
@font-face {
  font-family: Antonio-Light;
  src: url(assets/fonts/antonio/Antonio-Light.ttf); 
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(assets/fonts/montserrat/Montserrat-Regular.ttf); 
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(assets/fonts/montserrat/Montserrat-SemiBold.ttf); 
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(assets/fonts/montserrat/Montserrat-Bold.ttf); 
}

@font-face {
  font-family: Courgette-Regular;
  src: url(assets/fonts/courgette/Courgette-Regular.ttf); 
}

@font-face {
  font-family: Poppins-Regular;
  src: url(assets/fonts/poppins/Poppins-Regular.ttf); 
}

@font-face {
  font-family: Poppins-Medium;
  src: url(assets/fonts/poppins/Poppins-Medium.ttf); 
}

@font-face {
  font-family: Poppins-Bold;
  src: url(assets/fonts/poppins/Poppins-Bold.ttf); 
}

@font-face {
  font-family: Poppins-Light;
  src: url(assets/fonts/poppins/Poppins-Light.ttf); 
}

@font-face {
  font-family: NotoSans-Regular;
  src: url(assets/fonts/notosans/NotoSans-Regular.ttf); 
}

@font-face {
  font-family: Muli;
  src: url(assets/fonts/Muli.ttf);
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.card {
  background-color: #fff;
  color: #000;
  padding: 15px;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%);
  margin: 10px;
  border-radius: 2px;
  font-size: 1rem;
  display: -webkit-flex;
}

.card:hover {
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
  transition: all 500ms;
  /* background: #091554; */
  /* color: #fff; */
}

.container {
  margin: 0 auto;
  position: relative;
  padding: 25px;
  margin-left: 100px;
}

.row {
  display: -webkit-flex;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

.col {
  float: left;
  margin: 10px;
  min-height: 100px;
}

.withoutmargin {
  margin: 0 auto;
}

.col-2 {
  width: 20%;
  margin: 0 auto;
}

.col-3 {
  width: 33.3%;
  margin: 0 auto;
}

.col-4 {
  width: 25%;
  margin: 0 auto;
}

.col-5 {
  width: 50%;
  margin: 0 auto;
}

.col-4-reverse {
  width: 75%;
  margin: 0 auto;
}

.col-3-reverse {
  width: 66.6%;
  margin: 0 auto;
}

.col-2-reverse {
  width: 80%;
  margin: 0 auto;
}

.col-10 {
  width: 100%;
  margin: 0 auto;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.primaryTitle {
  font-size: 40px;
  line-height: 64px;
  text-transform: uppercase;
  float: left;
  margin-bottom: 30px;
  text-align: left;
  width: 100%;
  color: #000;
  font-weight: 100;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.noBullets {
  list-style: none;
}

.list {
  width: 100%;
}

.list li {
  border: 1px solid #1a1818;
  width: 100%;
  display: flex;
  background: #fff;
}

.list span {
  padding: 5px;
  min-height: 1rem;
}

.bgBlack {
  background: #1a1818;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .col-2,
  .col-2-reverse,
  .col-3,
  .col-3-reverse,
  .col-4,
  .col-4-reverse,
  .col-5,
  .col-10 {
    width: 100% !important;
    margin: 0 auto;
  }
}

@media screen and (max-width: 959px) {
  .row {
    display: inline;
  }
  .container {
    margin-left: 0px;
  }
  .col-4,
  .col-4-reverse,
  .col-3,
  .col-3-reverse,
  .col-2,
  .col-2-reverse {
    width: 50%;
    margin: 0 auto;
  }
  .col-10 {
    width: 100%;
    margin: 0 auto;
  }
}

.ra {
  color: #2bb2ff;
}
.mi {
  color: #ff5b5c;
}
.co {
  color: #d9d9d9;
}
.t-center{
  text-align: center;
}

.primary-color{
  color: #036873 !important;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin: 20px 100px;
}
.wrapper-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin: 20px 100px;
}
.content-text{
  display: contents;
}
.one {
  grid-column: 1 / 3;
  grid-row: 1;
}
.two {
  grid-column: 2 / 3;
  grid-row: 1;
}
.three {
  grid-column: 3 / 3;
  grid-row: 1;
}
.one-4{
  grid-column: 1 / 4;
  grid-row: 1;
}
.two-4{
  grid-column: 2 / 4;
  grid-row: 1;
}
.three-4{
  grid-column: 3 / 4;
  grid-row: 1;
}
.four-4{
  grid-column: 4 / 4;
  grid-row: 1;
}

.wrapper img{
  width: 100%;
}

.wrapper iframe{
  width: 100%;
  height: 100%;
}

.padding-top-0 { padding-top: 0px !important; }
.padding-top-40 { padding-top: 40px !important; }
.padding-top-25 { padding-top: 25px !important; }

.padding-5-20 { padding: 5px 20px !important; }

.padding-30 { padding: 30px !important; }

.padding-bottom-40 { padding-bottom: 40px !important; }
.padding-bottom-30 { padding-bottom: 30px !important; }

.no-border{border: 0px !important;} 
.no-shadow{box-shadow: none !important;}

.text-right{text-align: right;}
.float-right{float: right;}
.float-left{float: left;}